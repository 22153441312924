import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBoxesStacked, 
    faLandmark, faFileLines, faGear, faHouseLaptop, faIdCardClip, faChartLine, faMasksTheater, faCashRegister, faUpload, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { NavItem } from './NavItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { library } from "@fortawesome/fontawesome-svg-core";
import {Selector as SelectorIcon} from '../icons/selector';
import PropTypes from 'prop-types';
import {useContext,useState,useEffect} from 'react'
import { styled, useTheme } from '@mui/material/styles';
import { DashboardSidebarContext } from '../../Pages/ReactContexts/NavigateContext';
import DashboardSettings from './DashboardSettings';
import FetchModule from '../../Modules/FetchModule';
import { useFeatureFlags } from '../../Contexts/FeatureFlagContexts/FeatureFlagContext';
    library.add(faCircleUser, faBars, faMoneyBill, faLandmark, faHouse, faUsers, faBarcode, faBoxesStacked, 
    faFileLines, faGear, faHouseLaptop, faIdCardClip, faChartLine, faMasksTheater, faCashRegister, faUpload,faKeyboard);
    
    const DrawerHeader = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }));
    
const items = [
    {
        icon: (<FontAwesomeIcon className="menu-icon" icon="house"/>),
        title: "Dashboard",
        href: "/dashboard",
        feature: "dashboard"
    },
    
    // {
    //     icon: (<FontAwesomeIcon className="menu-icon" icon="money-bill"/>),
    //     title: "Price Manager",
    //     href: "/dashboard/prisync"
    // },
    // {
    //     icon: (<FontAwesomeIcon className="menu-icon" icon="boxes-stacked"/>),
    //     title: "Packing Boxes",
    //     href: "/dashboard/packing"
    // },
    // {
    //     icon: (<FontAwesomeIcon className="menu-icon" icon="masks-theater"/>),
    //     title: "Breaking",
    //     href: "/dashboard/session"
    // },
    // {
    //     icon: (<FontAwesomeIcon className="menu-icon" icon="landmark"/>),
    //     title: "Pantheon",
    //     href: "/dashboard/pantheon/all"
    // },
    {
        icon: (<FontAwesomeIcon className="menu-icon" icon="id-card-clip"/>),
        title: "Single Cards",
        href: "/dashboard/cards",
        feature: "cards"
    },
    {
      icon: (<FontAwesomeIcon className="menu-icon" icon="upload" />),
      title: "SKU Correction",
      href: "/dashboard/sku/correction",
      feature: "cards"
    },
    {
        icon: (<FontAwesomeIcon className="menu-icon" icon="boxes-stacked"/>),
        title: "View Repacks",
        href: "/dashboard/repack/view",
        feature: "repack"
    },
    {
        icon: (<FontAwesomeIcon className="menu-icon" icon="box"/>),
        title: "Create Repack",
        href: "/dashboard/repack/create",
        feature: "repack"
    },
    // {
    //     icon: (<FontAwesomeIcon className="menu-icon" icon="users"/>),
    //     title: "User Management",
    //     href: "/dashboard/usermangement"
    // },
    // {
    //     icon: (<FontAwesomeIcon className="menu-icon" icon="upload"/>),
    //     title: "Dear Manual Journals",
    //     href: "/dashboard/uploader"
    // },
    {
        icon: (<FontAwesomeIcon className="menu-icon" icon="gear"/>),
        title: "Settings",
        href: "/dashboard/settings",
        feature: "settings"
    },
    {
      
      icon: (<FontAwesomeIcon className="menu-icon" icon="fa-solid fa-barcode"/>),
      title: "Missing Barcode Search",
      href: "/dashboard/barcode",
      feature: "barcode"
  },
]
const drawerWidth = DashboardSettings.drawerWidth;
const MiniDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DashboardSidebar = (props) => {

  const {flags} = useFeatureFlags()

    


    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
      });
      const {isSidebarOpen, setSidebarOpen} = useContext(DashboardSidebarContext)

      function onClose() {
        setSidebarOpen(false)
      }
      const content = (
        <>
        <DrawerHeader>
          <div>
              <Typography
                      color="inherit"
                      variant="subtitle1"
                    >
                      {
                        isSidebarOpen ? "WeTheHobby" : "WTH"
                      }
                    </Typography>
            </div>
        </DrawerHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <Divider
              sx={{
                borderColor: '#2D3748',
                my: 3
              }}
            />
            <Box sx={{ flexGrow: 1 }}>
              {items.map((item) => (
                <NavItem
                  key={item.title}
                  icon={item.icon}
                  href={item.href}
                  title={item.title}
                  feature={item.feature}
                  flags={flags}
                />
              ))}
            </Box>
            <Divider sx={{ borderColor: '#2D3748' }} />
            <Box
              sx={{
                px: 2,
                py: 3
              }}
            >
              <Typography
                color="neutral.100"
                variant="subtitle2"
              >
                {isSidebarOpen ? "Need more features or are there bugs?" : "" }
              </Typography>
              <Typography
                color="neutral.200"
                variant="body2"
              >
                {isSidebarOpen ? "Message DEV team" : "" }

              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  mt: 2,
                  mx: 'auto',
                  width: '160px',
                  '& img': {
                    width: '100%'
                  }
                }}
              >

              </Box>

            </Box>
          </Box>
        </>
      );

    if (lgUp) {
        return (
          <MiniDrawer
            anchor="left"
            open={isSidebarOpen}
            onClose={() => setSidebarOpen(false)}
            PaperProps={{
              sx: {
                backgroundColor: 'neutral.900',
                color: '#FFFFFF',
                width: 280
              }
            }}
            variant="permanent"
          >
            {content}
          </MiniDrawer>
        );
      }
    
      return (
        <Drawer
          anchor="left"
          onClose={onClose}
          open={isSidebarOpen}
          PaperProps={{
            sx: {
              backgroundColor: 'neutral.900',
              color: '#FFFFFF',
              width: 280
            }
          }}
          sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
          variant="temporary"
        >
          {content}
        </Drawer>
      );
}
export default function(props) {
    //const navigate = useNavigate()

    return <DashboardSidebar />
}
