import React, { useMemo } from "react";
import "../../Hamburger.css";
import {
  GridRowModes,
  GridActionsCellItem,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiContext,
  GridRowModesModel,
} from "@mui/x-data-grid";
import { DataGridPro,useGridApiRef } from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import HistoryIcon from '@mui/icons-material/History';
import "../singles.css";

//custom filter operator
import SkuFilter from "../FilterComponents/SkuFilter";
import SimpleFilter from "../FilterComponents/SimpleFilter";

//custom toolbar and pagination
import CustomToolbar from "../ToolbarComponents/CustomToolbar";
import CustomPagination from "../FooterComponents/CustomPagination";

import FetchModule from "../../../Modules/FetchModule";
import { useState, useEffect } from "react";
import { useLocalStorage } from "../../../Utilities/useLocalStorage";

import DashboardDefault from "../CardOrderDefaults/DashboardDefault";
import HistorianModal from "../../../Components/HistorianModal/HistorianModal";

function DataGridComponent({
  rows,
  pageState,
  fetchDataAsync,
  rowCount,
  handlePageChange,
  handlePageSizeChange,
  onFilterChange,
  filterModel,
  changeColumnWidth,
  columnFilterDisplayModel,
  handleSortModelChange,
  sortModel,
  handleRowEditStart,
  handleRowEditStop,
  handleRowModesModelChange,
  processRowUpdate,
  handleProcessRowUpdateError,
  selectionModel,
  setSelectedRows,
  setSelectionModel,
  selectedRows,
  onRowSelectionModelChange,
  getGradedLastCard,
  getRawLastCard,
  GetPendingLastCard,
  getRetailLastCard,
  getTotalPrice,
  getTotalComp,
  rowModesModel,
  setColumnVisibility,
  setFilterModel,
  refresh,
  setRefresh,
  snackbar,
  setSnackbar,
  columnWidths,
  handleSaveClick,
  handleMoveGradeClick,
  handleMoveRawClick,
  handleCancelClick,
  handleEditClick,
  renderAutoCompleteCellPlayer,
  renderACDisplayCell,
  renderAutocompleteCellSkuItem,
  renderAttributesSelectCell,
  renderAttributesSelectDisplayCell,
  renderSelectCell,
  renderSelectDisplayCell,
  renderCellExpand,
  renderCustomTextInput,
  renderCustomDate,
  renderAutoCompleteUser,
  renderUser,
  renderCert,
  setJumpPage,
  renderCustomNumberInput,
  renderSimpleAutocomplete,
  players,
  users,
  dataFields,
  setDataFields,
  statusTypes,
  selectedForRepack,
  MoveCardConfirmationModal,
  headCard,
  changeStatusOnLiveCells,
  setIsJump,
  filterList,
  setFilterList
}) {
  /*
        Columns correspond to the relationship of the SingleCards table
        - field is the same name as the column within the table column name
        - type is the data type of that value
        - then the width corresponds to columnWidths which is a relationship
          within local stored columnWidths that uses the field as a dictionary of the field
           (see columnFilterDisplayModel)
    */

  const fetchData = new FetchModule();

  const useFetchGet = (url) => {
    const [data, setData] = useState(null);
    useEffect(() => {
      fetchData
        .fetchResponseAuthed(url, "GET")
        .then((res) => res.json())
        .then((data) => setData(data));
    }, [url]);
    return [data, setData];
  };

  //custom autocomplete filtering

  const attributes = [
    { value: "RC", label: "RC" },
    { value: "Auto", label: "Auto" },
    { value: "Auto RC", label: "Auto RC" },
    { value: "1st", label: "1st" },
    { value: "1st Auto", label: "1st Auto" }
  ];  


  const { Operators: AttributeOperators } = SimpleFilter({
    filterModel,
    setFilterModel,
    options: attributes,
    field: "attributes",
    label: "Attribute",
  });

  const { Operators: StatusOperators } = SimpleFilter({
    filterModel,
    setFilterModel,
    options: statusTypes,
    field: "status",
    label: "Status",
  })

  const { Operators: PurchasedByOperators } = SimpleFilter({
    filterModel,
    setFilterModel,
    options: users,
    field: "purchasedBy",
    label: "Purchased By",
  })

  const { Operators: PlayerOperators } = SimpleFilter({
    filterModel,
    setFilterModel,
    options: players,
    field: "player",
    label: "Player",
  });

  const { GradeOperators } = SkuFilter({
    filterModel,
    setFilterModel,
    field: "grade",
    skuType: "Grade",
  });


  const {Operators: YearOperators} = SkuFilter({
    filterModel,
    setFilterModel,
    field: "year",
    skuType: "Year",
  });

  const { Operators: ManufacturerOperators } = SkuFilter({
    filterModel,
    setFilterModel,
    field: "manufacturer",
    skuType: "Manufacturer",
  });

  const { Operators: SeriesOperators } = SkuFilter({
    filterModel,
    setFilterModel,
    field: "series",
    skuType: "Series",
  });

  const { Operators: LeagueOperators } = SkuFilter({
    filterModel,
    setFilterModel,
    field: "league",
    skuType: "League",
  });

  const { Operators: SetOperators } = SkuFilter({
    filterModel,
    setFilterModel,
    field: "setVariety",
    skuType: "Set",
  });

  //end custom autocomplete filtering

  const handleBlur = (event) => {
    event.target.blur();
  };

 
  const [colOrder, setColOrder] = useLocalStorage("cardColOrder", DashboardDefault().order)
  const [historianModalOpen, setHistorianModalOpen] = useState(false);
  const [historianModalData, setHistorianModalData] = useState([]);
  
  const handleHistorianModalOpen = async (publicId) => {
    const url = `/card/history/card/${publicId}`;
    const response = await fetchData.fetchResponseAuthed(url, "GET");
    const data = await response.json();
    setHistorianModalData(data);
    setHistorianModalOpen(true);
  };

  const handleHistorianModalClose = () => {
    setHistorianModalOpen(false);
    setHistorianModalData([]);
  };

  const calcCols = () => {
    var cols = [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: columnWidths["actions"],
        cellClassName: "actions",
        renderCell: (params) => {
          var id = params.id;
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          var retList = [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
          ];
  
          if (params.row?.inventoryId >= 10000000) {
            retList.push(
              <Tooltip title="Move to graded">
                <GridActionsCellItem
                  icon={<MoveUpIcon />}
                  label="Move to Graded"
                  onClick={handleMoveGradeClick(id)}
                />
              </Tooltip>
            );
          }
          if (params.row?.inventoryId >= 20000000) {
            retList.push(
              <Tooltip title="Move to raw">
                <GridActionsCellItem
                  icon={<LowPriorityIcon />}
                  label="Move to raw"
                  onClick={handleMoveRawClick(id)}
                />
              </Tooltip>
            );
          }
          retList.push(
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          );
  
          if (isInEditMode) {
            return retList;
          }
          else {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<HistoryIcon />}
                label="Audit"
                onClick={(auditProps)=>{
                  handleHistorianModalOpen(params.row.publicId)
                }}
                color="inherit"
              />,
  
              //,
              //<GridActionsCellItem
              //  icon={<DeleteIcon />}
              //  label="Delete"
              //  onClick={handleDeleteClick(id)}
              //  color="inherit"
              ///>,
            ];
          }
        },
      },
      {
        field: "inventoryId",
        headerName: "Inventory Id",
        type: "number",
        width: columnWidths["inventoryId"],
        editable: false,
      },
      {
        field: "purchase",
        headerName: "Purchased",
        type: "date",
        width: columnWidths["purchase"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
      },
      {
        field: "received",
        headerName: "Received",
        type: "date",
        width: columnWidths["received"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
      },
      {
        field: "player",
        headerName: "Player",
        width: columnWidths["player"],
        editable: true,
        filterOperators: PlayerOperators,
        renderEditCell: renderAutoCompleteCellPlayer,
        renderCell: renderACDisplayCell,
        type: "text",
      },
      {
        field: "year",
        headerName: "Year",
        width: columnWidths["year"],
        editable: true,
        skuType: "Year",
        filterOperators: YearOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
        type: "text",
      },
      {
        field: "manufacturer",
        headerName: "Manufacturer",
        width: columnWidths["manufacturer"],
        editable: true,
        skuType: "Manufacturer",
        filterOperators: ManufacturerOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
        type: "text",
      },
      {
        field: "series",
        headerName: "Series",
        width: columnWidths["series"],
        type: "text",
        skuType: "Series",
        editable: true,
        filterOperators: SeriesOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
      },
      {
        field: "setVariety",
        headerName: "Set/Variety",
        editable: true,
        skuType: "Set",
        width: columnWidths["setVariety"],
        type: "text",
        filterOperators: SetOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
      },
      {
        field: "attributes",
        headerName: "Attributes",
        type: "text",
        width: columnWidths["attributes"],
        editable: true,
        filterOperators: AttributeOperators,
        renderEditCell: renderAttributesSelectCell,
        renderCell: renderAttributesSelectDisplayCell,
      },
      {
        field: "serial",
        headerName: "Serial",
        type: "text",
        width: columnWidths["serial"],
        editable: true,
        renderEditCell: renderCustomTextInput,
      },
      {
        field: "grade",
        headerName: "Grade",
        width: columnWidths["grade"],
        skuType: "Grade",
        editable: true,
        type: "text",
        filterOperators: GradeOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
      },
      {
        field: "gradeSent",
        headerName: "Grading Sent",
        type: "date",
        width: columnWidths["gradeSent"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
      },
      {
        field: "gradeRec",
        headerName: "Grading Rec",
        type: "date",
        width: columnWidths["gradeRec"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
      },
      {
        field: "certNumber",
        headerName: "Cert #",
        type: "number",
        width: columnWidths["certNumber"],
        editable: true,
        renderEditCell: renderCustomTextInput,
        renderCell:renderCert
      },
      {
        field: "cardNumber",
        headerName: "Card #",
        type: "text",
        width: columnWidths["cardNumber"],
        editable: true,
        renderEditCell: renderCustomTextInput,
      },
      {
        field: "league",
        headerName: "League",
        width: columnWidths["league"],
        editable: true,
        skuType: "League",
        type: "text",
        filterOperators: LeagueOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
      },
      {
        field: "cost",
        headerName: "Cost",
        width: columnWidths["cost"],
        editable: true,
        type: "number",
        renderEditCell: renderCustomNumberInput,
      },
      {
        field: "fees",
        headerName: "Fees",
        width: columnWidths["fees"],
        editable: true,
        type: "number",
        renderEditCell: renderCustomNumberInput,
      },
      {
        field: "gradingFees",
        headerName: "Grading Fees",
        width: columnWidths["gradingFees"],
        editable: true,
        type: "number",
        renderEditCell: renderCustomNumberInput,
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        width: columnWidths["totalCost"],
        editable: false,
        valueGetter: (params) =>
          params.row.cost + params.row.gradingFees + params.row.fees,
        type: "number",
      },
      {
        field: "comp",
        headerName: "Comp",
        width: columnWidths["comp"],
        editable: true,
        type: "number",
        renderEditCell: renderCustomNumberInput,
      },
      {
        field: "vendor",
        headerName: "Vendor",
        width: columnWidths["vendor"],
        editable: true,
        type: "text",
        renderEditCell: renderSimpleAutocomplete,
      },
      {
        field: "description",
        headerName: "Description",
        width: columnWidths["description"],
        editable: true,
        type: "text",
        renderEditCell: renderCustomTextInput,
      },
      {
        field: "purchasedBy",
        headerName: "Purchase By",
        width: columnWidths["purchsedBy"],
        editable: true,
        type: "text",
        filterOperators: PurchasedByOperators,
        renderEditCell: renderAutoCompleteUser,
        renderCell:renderUser,
      },
      {
        field: "initials",
        headerName: "Initials",
        width: columnWidths["initials"],
        type: "text",
      },
      {
        field: "status",
        headerName: "Status",
        width: columnWidths["status"],
        editable: true,
        type: "string",
        filterOperators: StatusOperators,
        renderEditCell: renderSelectCell,
        renderCell: renderSelectDisplayCell,
      },
      {
        field: "flagged",
        headerName: "Flagged",
        width: columnWidths["flagged"],
        type: "boolean",
        editable: true,
      },
      {
        field: "isDear",
        headerName: "In Dear",
        width: columnWidths["isDear"],
        type: "boolean",
        editable: true,
      },
      {
        field: "sku",
        headerNmae: "SKU",
        width: columnWidths["sku"],
        type: "text",
      },
      {
        field: "name",
        headerName: "Name",
        width: columnWidths["name"],
        type: "text",
        editable: false,
        renderCell: renderCellExpand,
      },
    ]
    const sortedCols = cols.sort((a, b) => {
      return colOrder.indexOf(a.field) - colOrder.indexOf(b.field);
    });
    return sortedCols
  }

  const [colHolder, setCol] = React.useState(calcCols())

  const columns = React.useMemo(calcCols,[colOrder,dataFields,rowModesModel,filterModel]);


  const handleOrderChange = (params, event, details)=>{
    const newIndex  =  params.targetIndex-1
    const oldIndex = params.oldIndex-1
    const shiftOrders = (array, fromIndex, toIndex) => {
      // Remove the element from the array at the fromIndex
      const element = array.splice(fromIndex, 1)[0];
      // Insert the removed element into the array at the toIndex
      array.splice(toIndex, 0, element);
      setColOrder([...array])
    }
    shiftOrders(colOrder,oldIndex,newIndex)

  }

  const apiRef = useGridApiRef();
  const [oldHeadCard, setOldHeadCard] = React.useState(null);
  const rowHeight = 25;
  
  useEffect(() => {
    if (headCard !== null && headCard !== undefined && headCard !== oldHeadCard) {

      //Make a copy such that pass by reference doesnt cause issues
      setOldHeadCard(JSON.parse(JSON.stringify(headCard)));
      
      const rowIndex = rows.findIndex(row => { return row.id === headCard});
      
      if(rowIndex === -1){
        return
      }
      const scrollTo = Math.floor(rowHeight*apiRef.current.state.density.factor)*rowIndex; 
      apiRef.current.scroll({ top: scrollTo,left:0 });
      setIsJump(false);

      
    }
  }, [apiRef,rows]);
  const [isScrolling, setIsScrolling] = React.useState(false);
  const gridRef = React.useRef();
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(handleScroll.timeout);
      handleScroll.timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 250);
    };

    const observer = new MutationObserver(() => {
      const gridElement = gridRef.current?.querySelector('.MuiDataGrid-virtualScroller');
      if (gridElement) {
        gridElement.addEventListener('scroll', handleScroll);
        observer.disconnect(); // Stop observing once the element is found and event is bound
      }
    });

    observer.observe(gridRef.current, {
      childList: true,
      subtree: true,
    });

    return () => {
      const gridElement = gridRef.current?.querySelector('.MuiDataGrid-virtualScroller');
      if (gridElement) {
        gridElement.removeEventListener('scroll', handleScroll);
      }
      observer.disconnect();
    };
  }, []);
  return (
    <Box
      className="data-grid"
      sx={{
        height: "90%",
        overflow: "hidden",
        fontSize: "0.75rem",
        "& .MuiDataGrid-cell:hover": {
          color: "primary.main",
        },
      }}
      ref={gridRef}
    >
      <MoveCardConfirmationModal/>
      <HistorianModal
        open={historianModalOpen}
        onClose={handleHistorianModalClose}
        data={historianModalData}
        name="History Records"
      />
      <DataGridPro
        apiRef={apiRef}
        density="compact"
        page={pageState.page}
        pagination={true}
        paginationMode="server"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageSize={pageState.pageSize}
        rowCount={rowCount}
        rowHeight={rowHeight}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
        componentsProps={{
          pagination: {
            extendrowsize: "true",
            count: pageState.totalItems,
          },
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        components={{
          Pagination: () => (
            <CustomPagination
              rowCount={rowCount}
              pageState={pageState}
              refresh={refresh}
              fetchDataAsync={fetchDataAsync}
              handlePageSizeChange={handlePageSizeChange}
              getGradedLastCard={getGradedLastCard}
              getRawLastCard={getRawLastCard}
              GetPendingLastCard={GetPendingLastCard}
              getRetailLastCard={getRetailLastCard}
              getTotalPrice={getTotalPrice}
              getTotalComp={getTotalComp}
              handlePageChange={handlePageChange}
              setJumpPage={setJumpPage}
              selectedForRepack = {selectedForRepack}
            />
          ),
          Toolbar: () => (
            <CustomToolbar
              filterModel={filterModel}
              setFilterModel={setFilterModel}
              refresh={refresh}
              setRefresh={setRefresh}
              snackbar={snackbar}
              setSnackbar={setSnackbar}
              onFilterChange={onFilterChange}
              selectedRows={selectedRows}
              colOrder={colOrder}
              colVis = {columnFilterDisplayModel}
              totalItems={pageState.totalItems}
              statusTypes={statusTypes}
              dataFields={dataFields}
              setDataFields={setDataFields}
              players={players}
              changeStatusOnLiveCells={changeStatusOnLiveCells}
              filterList={filterList}
              setFilterList={setFilterList}
            />
          ),
        }}
        rows={rows}
        columns={columns}
        editMode="row"
        onRowModesModelChange={handleRowModesModelChange}
        //disableSelectionOnClick
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={(params,event)=>{
          handleRowEditStop(params,event,isScrolling)}
        }
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        //selection shit
        checkboxSelection
        setSelectionModel={setSelectionModel}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          setSelectedRows(selectedRows);
          setSelectionModel(ids);
          onRowSelectionModelChange(ids);
        }}
        rowSelectionModel={selectionModel}
        filterMode="server"
        //end selection
        onFilterModelChange={onFilterChange}
        filterModel={filterModel}
        onColumnWidthChange={changeColumnWidth}
        columnVisibilityModel={columnFilterDisplayModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibility(newModel)
        }
        onColumnOrderChange={handleOrderChange}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "actions"],
          },
        }}
        // Serverside sorting stuff
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
      />
    </Box>
  );
}

export default DataGridComponent;
