import { useState } from "react";
import packageJson from '../../package.json';

const version = packageJson.version;
// Utility function to help with using states within local storage
// so that a specific state is saved locally and persistant within a browser.
// Only works with JSON Formatted variables and not functions.
// could be editied for further functionality by using (not intended use):
// valuestore in setValue = value instanceof Function ? value(storedValue) : value;


const isVersioned = (value: any) => {
  if (typeof value === 'string') {
    return false;
  }
  return Object.keys(value).includes('version') && Object.keys(value).includes('data');
};

const getInitialData = (key:string, initialValue: any) => {
  try {
    let item = window.localStorage.getItem(key)
    if (item === null || item === 'undefined') {
      const versionedObject = {version: version, data: initialValue}
      window.localStorage.setItem(key, JSON.stringify(versionedObject))
      return initialValue.data;
    }
    const parsedValue = JSON.parse(item || '{}');
    if(!isVersioned(parsedValue)){
      const versionedObject = {version: version, data: parsedValue}
      window.localStorage.setItem(key, JSON.stringify(versionedObject))
      return versionedObject.data
    }
    return parsedValue.data
    } catch (error) {
        console.error('Error reading from local storage:', error);
        return initialValue;
    }
}

export function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = useState<any>(() => {
    return getInitialData(key, initialValue);
  });

  const setValue = (value: any | ((val:any) => any )) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error writing to local storage:', error);
    }
  };

  return [storedValue, setValue] as [any, (value: any | ((val: any) => any)) => void];
}