import {useContext, createContext} from "react";
import PropTypes from 'prop-types';
import { Box, Button, ListItem, Tooltip, } from '@mui/material';
import {ListItemIcon, ListItemText} from '@mui/material';
import { NavigateContext } from "../../Pages/ReactContexts/NavigateContext";

export const NavItem = (props) => {
  const { href, icon, title,feature, flags, ...others } = props;
  const active = href ? (window.location.pathname === href) : false;

  const {navigate} = useContext(NavigateContext)
  
  return (
    (Object.keys(flags).includes(feature.toLowerCase()) && flags[feature.toLowerCase()]) && 
    
    <Tooltip title={title} disableInteractive>
    <ListItem
      onClick={() => {
        navigate(href)}}
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
        backgroundColor: active && 'rgba(255,255,255, 0.08)',
        borderRadius: 1,
        color: active ? 'secondary.main' : 'neutral.300',
        fontWeight: active && 'fontWeightBold',
        justifyContent: 'flex-start',
        px: 3,
        textAlign: 'left',
        cursor: 'pointer',
        textTransform: 'none',
        width: '100%',
        '& .MuiButton-startIcon': {
          color: active ? 'secondary.main' : 'neutral.400'
        },
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, 0.08)'
        }
      }}
      {...others}
    >
      <ListItemIcon sx= {{
        height: '40px',
        display: 'flex',
        alignItems: 'center'
      }}>
        {icon}
      </ListItemIcon>
        <ListItemText>
          {title}
        </ListItemText>
        

    </ListItem>
    </Tooltip> 
  )
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string
};
