import {createContext, useContext, useState, useEffect} from 'react';

const UserContext = createContext(null);


let initialUser = null;
try {
    var data = localStorage.getItem("userData")
    if (data != null || data != undefined) {
        initialUser = JSON.parse(data)
    }
}   catch(e) {
    
}

export function UserProvider(props) {
    const [user, setUser] = useState(initialUser)
    const [invalidationReason,setInvalidationReason] = useState("")

    function parseExperationToken(token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
      
        const { exp } = JSON.parse(jsonPayload);
        return exp * 1000
    }

    function getExpirationDate(){
         var token  = user["token"]
        const myToken = parseExperationToken(token)
        return myToken
    }

    function isTokenValid(token) {
        const exp = getExpirationDate()
        const isValid = Date.now() < exp 

        if(!isValid) {
            setInvalidationReason("Your login has expired. Please login again.")
        }

        return isValid
      }
    function isLoggedIn() {

        const userExists = user == null
        if(userExists) {
            setInvalidationReason("You are not logged in. Please login.")
        }
        var loggedIn = userExists ? false : isTokenValid(user["token"])
        
        return loggedIn
    }
    const contextValues = {
        user,
        setUser,
        isLoggedIn,
        invalidationReason,
        setInvalidationReason,
        getExpirationDate,
    }
    return( 
        <UserContext.Provider value={contextValues}>
            {props.children}
        </UserContext.Provider>
    )
}

export function useUser() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("Invalid user")
    }
    return context;
}